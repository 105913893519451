export default {
  'task.title': 'Task',
  'task.annotation': 'Annotation',
  'task.audit': 'Review',
  'task.card.remain': 'Remaining {count}',
  'task.card.finish': 'I answered {count}',
  'task.card.other': 'There are no more questions in the current task, please select another task card',
  'task.card.end': 'Ended',
  'task.detail.upload': 'Upload attachment',
  'task.detail.upload.error1': 'File format error',
  'task.detail.upload.error2': 'Please keep the file size within 50 M',
  'task.detail.upload.desc':
    'Supported formats: image (png, jpg, jpeg, gif), video (mp4, mov), audio (mp3); single file size does not exceed 50M.',
  'task.detail.submit': 'Submit',
  'task.detail.skip': 'Skip',
  'task.detail.cancel': 'Exit',
  'task.detail.cancel.desc': 'Exit directly, the current input content will not be preserved',
  'task.detail.label.result': 'Label result',
  'task.detail.problematical': 'There is a problem with this question and it cannot be answered',
  'task.detail.kind.tips': 'Reminder',
  'task.detail.time.left': 'Only left until the end',
  'task.detail.time.left.desc':
    'If the timeout is not submitted, the question will be automatically recycled, and the filled content will not be saved',
  'task.detail.timeout.desc':
    'The current question has timed out and has been automatically collected. Please claim another question to continue answering~',
  'task.detail.error.required': 'Required fields are not filled in',
  'task.detail.audit.title': 'Evaluate the left-hand answer',
  'task.detail.audit.root.id': 'Source question ID',
  'task.detail.audit.question': 'Question',
  'task.detail.audit.question.id': 'Question ID',
  'task.detail.audit.annotator': 'Annotator',
  'task.detail.audit.auditor': 'Reviewer',
  'task.detail.audit.hint': 'Check if the left-hand labeled result is correct and meets the requirements',
  'task.detail.audit.quicker': 'shortcut: Yes - Alt + 1; No - Alt + 2',

  'task.custom.question.title': 'Custom topic scope',
  'task.custom.question.desc': 'Please enter the question ID (field: Data_id) a question ID line',
  'task.custom.question.placeholder': 'One question ID per line',
  'task.custom.root.title': 'Custom source question scope',
  'task.custom.root.desc':
    'Please enter the source question ID (field: Questionnaire_id) One line of the source question ID',
  'task.custom.root.placeholder': 'One line of source question ID',
  'task.diff.hint': 'No changed content',
  'task.diff.title': 'Content comparison',
  'task.diff.shortcut.key': 'shortcut: expand/collapse',
  'task.diff.benchmark': 'Benchmark',
  'task.diff.contrast': 'contrast',

  'task.question.all': 'All topics',
  'task.question.question': 'Only see marked as problematic',
  'task.question.scope': 'Custom topic scope',
  'task.question.placeholder':
    'Enter the source question ID (Questionnaire_id) you want to query, press Enter to search',
  'task.question.placeholder2': 'Enter the question ID (Data_id) you want to query, press Enter to search',
  'task.question.switch.sub': 'Switch subtopic',
  'task.question.switch.sub.placeholder': 'Select topic ID',

  'task.plugin': 'Plugin',
  'task.plugin.title1': 'For prompt',
  'task.plugin.title2': 'For dialogue',
  'task.plugin.title3': 'For questions in the conversation',
  'task.plugin.google': 'Google translation',
  'task.plugin.deepl': 'Deepl translation',
  'task.plugin.grammar.check': 'Syntax verification',

  'task.error.msg1': 'No questions marked as problematic',
  'task.error.msg2': 'No more questions',
  'task.error.msg3': 'Error location/Total number of characters',
  'task.error.msg4': 'Translation service is temporarily unavailable',
  'task.hint1': 'The number of unqualified questions is 0',
  'task.hint2': 'The number of questions not adopted is 0',
  'task.hint3': 'Only view non-compliant',
  'task.hint4': 'Only view not adopted',
  'task.hint5': 'View source question combination',
  'task.hint6': 'This question has been marked as having a problem and cannot be answered.',
  'task.hint7': 'Please enter your username',

  'member.title': 'Member Management',
  'member.del.desc': 'Are you sure to remove this user from this team',
  'member.invite': 'Invite members',
  'member.team': 'Team',
  'member.team.join': 'Invite you to join the team',
  'member.team.join.success': 'You have joined, please go to the annotation page',
  'member.link.timeout': 'Link has timed out',
  'member.join': 'Join',
  'member.hint':
    'Tips: If a user has joined a team and then joins a new team, they will automatically be withdrawn from the previous team.',
  'member.operate.hint':
    'Operation mode: send the link to members, members click the link through the invitation, after joining the default for ordinary members',
  'member.copy.url': 'Copy link',
  'member.regenerate': 'Regenerate',
  'member.validity': 'Valid until',

  'common.copy': 'Copy',
  'common.copy.success': 'Copy success',
  'common.none': 'None',
  'common.username': 'Username',
  'common.username.id': 'User ID',
  'common.role': 'Role',
  'common.operate': 'Operation',
  'common.save.placeholder': 'Save successfully',
  'common.preview': 'Preview',
  'common.check': 'View',
  'common.check.single': 'Single question viewing mode',
  'common.switch': 'Change the topic',
  'common.prev': 'Previous question',
  'common.next': 'Next question',
  'common.translation': 'Translation',
  'common.del': 'Remove',
  'common.sure': 'Sure',
  'common.cancel': 'Cancel',
  'common.search': 'Search',
  'common.personal.center': 'Individual center',
  'common.quit': 'Log out',
  'common.sort': 'Sort',
  'member.super.admin': 'Super Administrator',
  'member.admin': 'Administrator',
  'member.regular': 'Regular Member',
};
